import { isDEVMODE, domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

// We'll create a <div></div> for page transition
export let pageTransitionDiv

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    if (isDEVMODE) console.log(`Transition Entering Page : ${to.dataset.taxiView}`)

    gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 0.8 },
        onStart: () => {
          // Reset window scroll
          window.scrollTo(0, 0)
          // Updating the ScrollTriggers
          ScrollTrigger.refresh()
        },
        onComplete: () => {
          // Display the new page
          done()
          // Removing body className while animating
          domStorage.body.classList.add('is--animating')
          // Removing the pageTransitionDiv from the DOM
          domStorage.body.removeChild(pageTransitionDiv)
        },
      })
      // .from(to, { y: -20 }, 0)
      .to(pageTransitionDiv, { clipPath: 'inset(0% 0% 100% 0%)', overwrite: true }, 0)
      .add(pageIntro, 0)
  }

  onLeave({ from, trigger, done }) {
    if (isDEVMODE) console.log(`Transition Leaving Page : ${from.dataset.taxiView}`)

    // We need to create a div for page transitions
    // <div class="PageTransition"></div>
    pageTransitionDiv = document.createElement('div')
    pageTransitionDiv.className = 'PageTransition'
    pageTransitionDiv.innerHTML = `
      <div class="Logo">
        <svg width="115" height="49" viewBox="0 0 115 49" fill="none">
          <path d="M23.8126 22.5791H20.4236V0.221481H28.6745C30.4528 0.221481 31.9736 0.856166 33.237 2.12553C34.5064 3.38293 35.1411 4.90976 35.1411 6.70005C35.1411 8.49034 34.5064 9.99921 33.237 11.2626C31.9796 12.52 30.4588 13.1547 28.6745 13.1547H23.8185V22.5731L23.8126 22.5791ZM23.8126 9.77168H28.6685C29.5127 9.77168 30.2372 9.4723 30.842 8.86756C31.4348 8.27479 31.7341 7.55628 31.7341 6.71203C31.7341 5.86778 31.4348 5.1313 30.842 4.52656C30.2372 3.92181 29.5127 3.62243 28.6685 3.62243H23.8126V9.77767V9.77168Z" />
          <path d="M39.2486 20.4538C37.6978 18.903 36.9194 17.0289 36.9194 14.8315V0.209763H40.3264V14.8315C40.3264 16.0888 40.7695 17.1666 41.6556 18.0468C42.5538 18.9449 43.6255 19.388 44.8709 19.388C46.1164 19.388 47.2061 18.9389 48.0863 18.0468C48.9844 17.1606 49.4275 16.0888 49.4275 14.8315V0.209763H52.8344V14.8315C52.8344 17.0289 52.0561 18.903 50.4933 20.4538C48.9425 22.0046 47.0684 22.783 44.8709 22.783C42.6735 22.783 40.8114 22.0046 39.2486 20.4538Z" />
          <path d="M55.4869 22.5791V0.221481H62.4984C64.2707 0.221481 65.7796 0.844191 67.031 2.0956C68.2824 3.35299 68.9051 4.86785 68.9051 6.6282C68.9051 8.20294 68.4022 9.58008 67.3903 10.7656C68.2525 11.3584 68.9291 12.1188 69.4201 13.0469C69.941 13.9929 70.1985 15.0108 70.1985 16.0946C70.1985 17.8849 69.5638 19.4117 68.2944 20.6691C67.037 21.9385 65.5162 22.5731 63.7319 22.5731H55.481L55.4869 22.5791ZM58.8759 9.64595H62.4984C63.3307 9.64595 64.0372 9.34657 64.624 8.75379C65.2168 8.173 65.5162 7.46048 65.5162 6.6282C65.5162 5.79593 65.2168 5.08939 64.624 4.50261C64.0432 3.90984 63.3307 3.61046 62.4984 3.61046H58.8759V9.63996V9.64595ZM58.8759 19.1902H63.7319C64.5761 19.1902 65.3006 18.8908 65.9054 18.286C66.4981 17.6813 66.7975 16.9508 66.7975 16.1006C66.7975 15.2503 66.4981 14.5378 65.9054 13.945C65.3006 13.3403 64.5761 13.0409 63.7319 13.0409H58.8759V19.1961V19.1902Z" />
          <path d="M75.8388 0.221481V19.1842H83.8502V22.5731H72.4438V0.221481H75.8328H75.8388Z" />
          <path d="M89.5982 22.579H86.2092V0.257233H89.5982V22.579Z" />
          <path d="M102.442 19.3938C103.795 19.3938 105.058 19.0765 106.238 18.4418V22.1421C105.01 22.5792 103.741 22.8007 102.442 22.8007C99.2981 22.8007 96.6097 21.687 94.3823 19.4597C92.1549 17.2323 91.0413 14.5439 91.0413 11.4004C91.0413 8.25688 92.1549 5.56846 94.3823 3.34107C96.6097 1.11369 99.2981 0 102.442 0C103.741 0 105.01 0.215553 106.238 0.640672V4.35896C105.058 3.71231 103.795 3.38897 102.442 3.38897C100.232 3.38897 98.3461 4.17335 96.7893 5.74809C95.2266 7.31085 94.4482 9.19694 94.4482 11.4004C94.4482 13.6038 95.2266 15.4959 96.7893 17.0527C98.3521 18.6094 100.238 19.3938 102.442 19.3938Z" />
          <path d="M17.855 26.2195V48.0144H14.5319V42.9249H7.13122L3.91588 48.0144H0L13.7834 26.2195H17.849H17.855ZM9.22089 39.6258H14.5379V31.2312L9.22089 39.6258Z" />
          <path d="M20.262 48.0144V26.2195H25.9861C28.9919 26.2195 31.5605 27.2853 33.6921 29.4169C35.8117 31.5485 36.8775 34.1172 36.8775 37.1229C36.8775 40.1287 35.8177 42.6794 33.6921 44.817C31.5605 46.9486 28.9919 48.0144 25.9861 48.0144H20.262ZM23.5492 44.7272H25.9861C28.0758 44.7272 29.872 43.9787 31.363 42.4878C32.8479 41.0029 33.5843 39.2186 33.5843 37.1289C33.5843 35.0393 32.8419 33.237 31.363 31.7521C29.872 30.2612 28.0758 29.5127 25.9861 29.5127H23.5492V44.7272Z" />
          <path d="M37.5243 48.0144V26.2195H43.2484C46.2542 26.2195 48.8229 27.2853 50.9545 29.4169C53.0741 31.5485 54.1399 34.1172 54.1399 37.1229C54.1399 40.1287 53.08 42.6794 50.9545 44.817C48.8229 46.9486 46.2542 48.0144 43.2484 48.0144H37.5243ZM40.8115 44.7272H43.2484C45.3381 44.7272 47.1344 43.9787 48.6253 42.4878C50.1102 41.0029 50.8467 39.2186 50.8467 37.1289C50.8467 35.0393 50.1042 33.237 48.6253 31.7521C47.1344 30.2612 45.3381 29.5127 43.2484 29.5127H40.8115V44.7272Z" />
          <path d="M71.0187 48.0144H67.2885L62.5224 38.8294H58.7921V48.0144H55.4869V26.2195H63.5283C65.2647 26.2195 66.7496 26.8363 67.977 28.0757C69.2165 29.3032 69.8332 30.7941 69.8332 32.5364C69.8332 33.8178 69.4739 34.9854 68.7494 36.0392C68.0489 37.075 67.1268 37.8355 65.9952 38.3264L71.0187 48.0144ZM63.5223 35.5243C64.3426 35.5243 65.0491 35.2309 65.6419 34.6381C66.2227 34.0573 66.5101 33.3567 66.5101 32.5364C66.5101 31.7161 66.2227 30.9917 65.6419 30.4049C65.0551 29.8181 64.3486 29.5187 63.5223 29.5187H58.7861V35.5183H63.5223V35.5243Z" />
          <path d="M83.5628 26.2195V29.5247H75.749V35.4344H81.7785V38.7396H75.749V44.7092H83.5628V48.0144H72.4438V26.2195H83.5628Z" />
          <path d="M91.3946 48.2122C89.9515 48.1524 88.6223 47.7632 87.4068 47.0566C86.0237 46.2543 85.0836 45.1765 84.5867 43.8293L87.6942 42.6857C87.9457 43.3563 88.4726 43.9012 89.263 44.3143C89.9635 44.6676 90.7239 44.8652 91.5502 44.9071C92.8196 44.961 93.8674 44.6496 94.7057 43.979C95.4062 43.4102 95.7775 42.7575 95.8194 42.027C95.8793 40.6379 94.4243 39.5242 91.4484 38.692C86.7901 37.3807 84.5268 35.1653 84.6705 32.0338C84.7603 29.8423 85.7004 28.2017 87.4906 27.112C88.8498 26.2797 90.4844 25.9085 92.3945 25.9983C94.7117 26.1001 96.5678 26.9084 97.9689 28.4173L95.5499 30.6686C94.7596 29.8184 93.6579 29.3573 92.2448 29.2974C91.2509 29.2555 90.4006 29.3813 89.7001 29.6806C88.6043 30.1477 88.0295 30.9799 87.9816 32.1775C87.9218 33.5486 89.3767 34.6503 92.3526 35.4826C95.035 36.2371 96.9331 37.3388 98.0468 38.8057C98.8192 39.8236 99.1784 40.9433 99.1305 42.1707C99.0467 43.8952 98.2683 45.3621 96.7834 46.5597C95.4003 47.6674 93.7477 48.2182 91.8197 48.2182C91.676 48.2182 91.5383 48.2182 91.4065 48.2003L91.3946 48.2122Z" />
          <path d="M106.304 48.2122C104.861 48.1524 103.531 47.7632 102.316 47.0566C100.933 46.2543 99.9927 45.1765 99.4957 43.8293L102.603 42.6857C102.855 43.3563 103.382 43.9012 104.172 44.3143C104.873 44.6676 105.633 44.8652 106.459 44.9071C107.729 44.961 108.776 44.6496 109.615 43.979C110.315 43.4102 110.687 42.7575 110.728 42.027C110.788 40.6379 109.333 39.5242 106.358 38.692C101.699 37.3807 99.4359 35.1653 99.5796 32.0338C99.6694 29.8423 100.609 28.2017 102.4 27.112C103.759 26.2797 105.393 25.9085 107.304 25.9983C109.621 26.1001 111.477 26.9084 112.878 28.4173L110.459 30.6686C109.669 29.8184 108.567 29.3573 107.154 29.2974C106.16 29.2555 105.31 29.3813 104.609 29.6806C103.513 30.1477 102.939 30.9799 102.891 32.1775C102.831 33.5486 104.286 34.6503 107.262 35.4826C109.944 36.2371 111.842 37.3388 112.956 38.8057C113.728 39.8236 114.087 40.9433 114.04 42.1707C113.956 43.8952 113.177 45.3621 111.692 46.5597C110.309 47.6674 108.657 48.2182 106.729 48.2182C106.585 48.2182 106.447 48.2182 106.316 48.2003L106.304 48.2122Z" />
        </svg>
      </div>
    `

    gsap
      .timeline({
        delay: 0.2,
        defaults: { ease: 'power3.inOut', duration: 0.8 },
        onStart: () => {
          // We append the pageTransitionDiv to the body
          document.body.appendChild(pageTransitionDiv)
        },
        onComplete: () => { 
          // Remove the last page
          done()
        }
      })
      .to(from, { y: 20 }, 0)
      .fromTo(pageTransitionDiv, { clipPath: 'inset(100% 0% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }, 0)
      .fromTo(pageTransitionDiv.children[0], { opacity: 0, scale: 1.1 }, { opacity: 1, scale: 1 }, 0.3)
  }
}
